<template>
  <div>
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1"
      variant="primary"
      @click="$router.push({ name: 'devolucao' })"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">Voltar</span>
    </b-button>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col>
              <b-col>
                <b-row>
                  <b-col md="12">
                    <b-row>
                      <h5>
                        <strong class="text-primary">Usuário:</strong>
                        {{ devolucao.usuario_name }}
                      </h5>
                    </b-row>

                  </b-col>
                  <b-col md="12">
                    <b-row>
                      <h5>
                        <strong class="text-primary">Criado em:</strong>
                        {{ dataHora(devolucao.created_at, true) }}
                      </h5>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <h5>
                        <strong class="text-primary">Motivo da Devolução:</strong>
                        {{ devolucao.motivo_devolucao }}
                      </h5>
                    </b-row>
                  </b-col>
                </b-row>

              </b-col>
            </b-col>
            <b-col>
              <h5>
                <strong class="text-primary">Descrição da Devolução:</strong>
                {{ devolucao.descricao }}
              </h5>
              <h5>
                <strong class="text-primary">Qnt. de devoluções:</strong>
                {{ devolucao.operacoes.length }}
              </h5>
              <h5>
                <strong class="text-primary">Qnt. de clientes:</strong>
                {{ getClientes(devolucao.operacoes) }}
              </h5>
              <h5>
                <strong class="text-primary">Valor Nominal Total:</strong>
                {{ sumNominalValue(devolucao.operacoes) }}
              </h5>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col cols="12">
              <b-row align-h="between">
                <b-col
                  md="2"
                  sm="4"
                  class="my-1"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      class="w-50"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="8"
                  class="my-1"
                >
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    size="sm"
                    placeholder="Pesquisar"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-table
              :items="devolucao.operacoes"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              hover
              small
              striped
              responsive
              class="text-center"
              @row-clicked="rowClick"
            >
              <template #cell(remessa)="data">
                <b-link
                  :to="{ name: 'remessa-view', params: { id: data.item.remessa_id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.remessa }}
                </b-link>
              </template>
              <template #cell(num_operacao)="data">
                {{ data.item.num_operacao }}
              </template>
            </b-table>
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal"
      ref="modal"
      hide-footer
      centered
      size="lg"
      title="Detalhes da Operação"
    >
      <InfoCard
        :id-op="id"
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BFormSelect,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BCol,
  BCard,
  BTable,
  BButton,
  BLink,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import InfoCard from '@/views/backoffice/operacao/cards/OperacoesInfo.vue'

import axios from '@/../axios-auth'

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BFormInput,
    BPagination,
    InfoCard,
    BModal,
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      filter: '',
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      pageOptions: [10, 15, 20],
      id: null,
      devolucao: {
        id: '',
        descricao: '',
        motivo_devolucao: '',
        usuario_name: '',
        operacoes: [],
        usuario: {},
        created_at: '',
      },
      operacoes: [],
      fields: [
        { key: 'num_operacao', label: 'operacao', class: 'text-center' },
        'remessa',
        { key: 'cliente', class: 'text-left' },
        'vencimento',
        { key: 'valor_nominal', class: 'text-right' },
      ],
    }
  },
  async created() {
    const devId = this.$route.params.id
    await axios.get(`api/v1/devolucao/detalhe/${devId}`, {
      headers: {
        Accpet: 'Application/json',
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
      },
    }).then(res => {
      const dt = res.data.dados.devolucao

      dt.devolucao.map(dev => {
        this.devolucao.id = dev.id
        this.devolucao.motivo_devolucao = dev.motivo_devolucao
        this.devolucao.descricao = dev.descricao
        this.devolucao.usuario_empresa = dev.usuario_empresa
        this.devolucao.usuario = dev.name
        this.devolucao.created_at = dev.created_at
        this.devolucao.usuario_name = dev.name
      })

      dt.operacoes.map(op => (
        this.devolucao.operacoes.push({
          id: op.id,
          num_operacao: op.numero_operacao,
          valor_nominal: this.valorBr(op.valor_nominal, true),
          remessa: op.numero_remessa,
          vencimento: this.dataHora(op.data_vencimento, true),
          cliente: this.fisrtLastName(op.cliente.nome),
          remessa_id: op.remessa_id,
        })
      ))

      this.devolucao.usuario_name = dt.usuario.name

      this.totalRows = this.devolucao.operacoes.length
    })
  },
  methods: {
    getClientes(operacoes) {
      const clientesUnicos = []
      const clientesDuplicados = []
      operacoes.forEach(op => {
        if (clientesUnicos.includes(op.cliente)) {
          if (!clientesDuplicados.includes(op.cliente)) {
            clientesDuplicados.push(op.cliente)
          }
        } else {
          clientesUnicos.push(op.cliente)
        }
      })
      return clientesUnicos.length
    },
    sumNominalValue(operacoes) {
      const total = operacoes.reduce((acc, op) => {
        const valorNumerico = parseFloat(op.valor_nominal.replace(/[^\d,.-]|(?<=\d)\.(?=\d)/g, '').replace(',', '.'))
        return acc + valorNumerico
      }, 0)
      const totalFormatado = `R$ ${total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      return totalFormatado
    },

    rowClick(item) {
      this.id = null
      this.id = item.id
      this.$bvModal.show('modal')
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
